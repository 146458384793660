.resource-details-content {
    .back-button-container {
        @apply flex justify-start;
    }

    .header-container {
        @apply my-4 mx-2;

        .header-resource-title-container {
            @apply flex items-center gap-2;

            .header-resource-title {
                @apply text-2xl font-bold m-0;
            }
        }

        .task-description {
            @apply text-sm leading-3 text-gray-600;
        }
    }

    .content-container.expense-details {
        @apply w-full flex flex-row gap-4 items-start border-t border-gray-300 mt-5 py-1;

        .task-info-card {
            @apply rounded bg-white shadow;

            .task-info-card-header {
                @apply flex justify-between items-center gap-2 px-5 py-3 border-b border-gray-300;

                .header-title {
                    @apply text-xl font-bold;
                }
            }

            .task-info-card-content {
                @apply pb-2 divide-y divide-gray-300;

                .task-interval-container {
                    @apply flex justify-between px-5 py-2;

                    .times-container {
                        .task-time {
                            @apply text-sm text-gray-600;

                            span {
                                @apply font-bold text-black;
                            }
                        }
                    }

                    .all-day-container {
                        @apply flex items-center;

                        .all-day-text {
                            @apply text-sm font-bold;
                        }
                    }
                }

                .task-info-card-row {
                    @apply flex justify-between items-center px-5 py-2;
                    min-width: 20rem;

                    & > p,
                    & > div {
                        &.label {
                            @apply w-1/2 text-sm text-gray-500;
                        }

                        &.value {
                            @apply w-1/2 font-medium;
                        }
                    }

                    .task-color-container {
                        @apply flex items-center gap-2;

                        .task-color-hex {
                            @apply text-base sm:text-sm font-bold text-gray-600;
                        }
                    }
                }

                .task-info-button-container {
                    @apply p-4 pb-2;
                }

                .billable-icon {
                    @apply h-7 w-7;

                    &.green {
                        @apply text-avo-green;
                    }

                    &.red {
                        @apply text-avo-red;
                    }
                }
            }
        }

        .task-activities-container {
            @apply w-full;

            .buttons-container {
                @apply flex items-center gap-2;
            }
        }
    }
}
