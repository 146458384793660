.add-task-time-log-form-container {
    @apply space-y-6;

    .add-task-time-log-form-row {
        @apply flex items-center gap-2;

        &.asym-split {
            & > *:first-child {
                @apply w-2/3;
            }

            & > *:nth-child(2) {
                @apply w-1/3;
            }
        }
    }

    .has-error {
        input.avo-input[type="time"] {
            @apply text-avo-red;
        }
    }

    .time-error-message {
        @apply p-0.5 text-sm font-medium text-avo-red;
    }

    .interval-picker-container {
        .interval-picker-label {
            @apply block text-sm font-medium text-gray-700 mb-1;
        }

        .interval-datepickers-container {
            @apply flex items-center gap-2;
        }
    }

    .textarea-container {
        label.note-textarea-label {
            @apply block text-sm font-medium text-gray-700 mb-1;
        }

        .note-textarea {
            @apply shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md;
        }
    }
}
