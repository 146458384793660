.invoice-preview-page-container {
  @apply h-content;

  .invoice-preview-container {
    @apply w-full h-content py-4;
  }


  .no-file {
    @apply text-sm text-center font-semibold text-gray-500 py-4;
  }
}

.page-info {
  width: 100%;
}
