.avo-calendar-view-select-container {
    @apply relative md:static flex ml-6 md:ml-4  md:items-center;

    .avo-calendar-view-select-menu {
        @apply relative;

        .avo-calendar-view-select-menu-opener {
            @apply hidden md:flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50;

            .avo-calendar-view-select-menu-opener-icon {
                @apply ml-2 h-5 w-5 text-gray-400;
            }
        }

        .avo-calendar-view-select-menu-mobile-opener {
            @apply flex md:hidden items-center rounded-full border border-transparent -mx-2 p-2 text-gray-400 hover:text-gray-500;

            .avo-calendar-view-select-menu-opener-icon {
                @apply h-5 w-5;
            }
        }

        .avo-calendar-view-select-items-container {
            @apply absolute right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md divide-y md:divide-y-0 divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;

            .avo-calendar-view-select-menu-section {
                @apply py-1;

                .avo-calendar-view-select-option-container {
                    @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
                }
            }
        }
    }
}
