.avo-datatable-container {
    @apply -my-2 sm:-mx-6 lg:-mx-8;

    .avo-datatable-container-inner {
        @apply py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8;

        .avo-datatable-container-shadow {
            @apply max-h-full shadow sm:rounded-lg;

            .avo-datatable {
                @apply min-w-full divide-y divide-gray-200;

                .avo-datatable-head {
                    @apply bg-gray-50;

                    .avo-datatable-head-td {
                        @apply px-5 py-3;

                        .avo-datatable-header-container {
                            @apply flex justify-between items-center;

                            .loader-container {
                                @apply flex items-center;

                                .table-title {
                                    @apply text-xl font-bold mr-2;
                                }
                            }
                        }
                    }

                    th.column-title {
                        @apply px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider select-none;
                    }
                }

                .avo-datatable-body {
                    @apply bg-white divide-y divide-gray-200;

                    .table-row:hover {
                        @apply bg-gray-100;
                    }

                    .table-cell {
                        @apply max-w-xs p-2 whitespace-normal text-sm text-gray-900 border-r border-gray-300;
                    }
                }

                .avo-datatable-footer {
                    @apply w-full;
                }
            }
        }
    }
}

.avo-search-filters-container {
    @apply w-full flex items-center justify-between px-5 py-2;

    .avo-filters-container {
        @apply mr-3;
    }

    .avo-search-container {
        @apply flex w-full;
    }

    .avo-search-container-button-container {
        @apply w-full flex justify-end;
    }
}

.filters-container {
    @apply flex items-center px-5 py-2;

    .filters-text {
        @apply text-sm;
    }

    .filters-list {
        @apply flex gap-2 ml-2;
    }

    .badge-button {
        @apply flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white;
    }
}
