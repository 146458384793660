.empowerment-info-cards-container {
    @apply w-full mt-4;

    .row {
        @apply w-full flex flex-wrap justify-between gap-2;

        &:not(:first-child) {
            @apply mt-4;
        }

        .left,
        .right {
            width: calc(theme("width[1/2]") - 8px);
        }

        .empowerment-info-card {
            @apply w-full h-full rounded bg-white shadow;

            .header {
                @apply flex justify-between items-center px-5 py-3 border-b border-gray-300;

                .header-title {
                    @apply text-xl font-bold;
                }
            }

            .content {
                @apply px-5 py-2;
            }

            .center-loader {
                @apply h-full pt-10 flex justify-center;
            }
        }

        .empowerment-locations-card {
            .section {
                .section-title {
                    @apply text-sm text-gray-500 font-bold py-1 border-b border-gray-300;
                }

                .location-rows-container {
                    @apply grid grid-cols-3 py-2;

                    .location-container {
                        .location-name {
                            @apply text-sm text-primary font-medium leading-6 underline;
                        }
                    }
                }
            }

            .no-locations {
                @apply text-sm text-gray-500 py-1;
            }
        }

        .empowerment-history-card {
            .content {
                @apply py-4;

                .logs-list-container {
                    @apply flow-root;

                    .logs-list {
                        @apply -mb-8;
                    }
                }
            }
        }
    }
}
