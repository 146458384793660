.add-md-manual-subscription-hearing-form {
    @apply py-4 space-y-4;

    .textarea-container {
        label.note-textarea-label {
            @apply block text-sm font-medium text-gray-700 mb-1;
        }

        .note-textarea {
            @apply shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md;
        }
    }
}
