.avo-calendar-week-view-container {
    @apply flex flex-auto flex-col overflow-auto bg-white;

    .avo-calendar-week-view-inner-container {
        @apply flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full;

        .avo-calendar-week-view-calendar-header {
            @apply sticky top-0 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8;

            .avo-calendar-week-view-calendar-header-days-mobile {
                @apply grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden;

                .avo-calendar-week-view-calendar-day-button {
                    @apply flex flex-col items-center pt-2 pb-3;

                    .avo-calendar-week-view-calendar-day-number {
                        @apply mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900;

                        &.is-selected {
                            @apply rounded-full text-white bg-gray-900;

                            &.is-today {
                                @apply bg-primary;
                            }
                        }

                        &.is-today {
                            @apply text-primary;
                        }
                    }
                }
            }

            .avo-calendar-week-view-calendar-header-days-desktop {
                @apply -mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid;

                .avo-calendar-week-view-calendar-header-placeholder {
                    @apply col-end-1 w-14;
                }

                .avo-calendar-week-view-calendar-day-container {
                    @apply flex items-center justify-center py-3;

                    .avo-calendar-week-view-calendar-day-text {
                        @apply flex items-baseline gap-1.5;

                        .avo-calendar-week-view-calendar-day-number {
                            @apply items-center justify-center font-semibold text-gray-900;

                            &.is-today {
                                @apply flex h-8 w-8 rounded-full bg-primary text-white;
                            }
                        }
                    }
                }
            }
        }

        .avo-calendar-week-view-calendar-body-container {
            @apply flex flex-auto;

            .avo-calendar-week-view-calendar-body-sticky-container {
                @apply sticky left-0 w-14 flex-none bg-white ring-1 ring-gray-100;
            }

            .avo-calendar-week-view-calendar-body-lines-tasks-container {
                @apply grid flex-auto grid-cols-1 grid-rows-1;

                .avo-calendar-week-view-calendar-body-horizontal-lines-container {
                    @apply col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100;

                    .avo-calendar-week-view-calendar-body-container-offset {
                        @apply row-end-1 h-7;
                    }

                    .avo-calendar-week-view-calendar-body-horizontal-line {
                        @apply sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400;
                    }
                }

                .avo-calendar-week-view-calendar-body-vertical-lines-container {
                    @apply col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7;
                }

                .avo-calendar-week-view-calendar-body-tasks-list {
                    @apply col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8;

                    .avo-calendar-week-view-calendar-body-task {
                        @apply relative mt-px flex sm:col-start-3;

                        .avo-calendar-week-view-calendar-body-task-link {
                            @apply absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100;

                            .avo-calendar-week-view-calendar-body-task-title {
                                @apply order-1 font-semibold text-primary-dark;
                            }

                            .avo-calendar-week-view-calendar-body-task-time {
                                @apply text-primary-dark;
                            }
                        }
                    }
                }
            }
        }
    }
}
