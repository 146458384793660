.file-container {
    height: calc(100vh - 12.5rem);
}


.document-editor-content {
    .back-button-container {
        @apply flex justify-start;
    }

    .header-resource-title-container {
        @apply pt-2 pb-5;

        .document-name-container {
            .header-resource-title {
                @apply text-2xl pb-2;

                > span {
                    @apply font-bold;
                }
            }

            .header-resource-disclaimer {
                @apply flex items-center gap-1 font-light;
            }
        }

        .loader-container {
            @apply justify-start p-4;
        }
    }
}