.fc .fc-toolbar button.fc-button {
    @apply bg-primary border-none shadow-none;

    &:hover {
      @apply bg-primary-dark;
    }

    &:disabled {
      @apply bg-gray-500;
    }

    &.fc-button-active {
      @apply bg-primary-dark shadow-none;
    }
}

.fc-day-mon, .fc-day-tue, .fc-day-wed, .fc-day-thu, .fc-day-fri {
  @apply bg-white #{!important};
}

.resource-select {
  @apply flex gap-2 pb-6;

  .avo-select-container {
    @apply flex-row items-center gap-2;

    .avo-select {
      @apply w-72;
    }
  }

  label {
    @apply text-xl #{!important};
  }
}