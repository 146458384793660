.tooltip {
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -25px;
  left: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}