.actions {
  @apply relative;

  .actions-icon {
    @apply w-5 h-auto m-0 cursor-pointer;
  }

  .actions-options {
    @apply absolute bg-white shadow-md block cursor-pointer -translate-x-full rounded-s;

    .option {
      @apply p-2 m-0 flex gap-1 min-w-max items-center justify-between;

      .option-icon {
        @apply w-4 h-auto m-0;
      }
    }
    .option:hover {
      @apply bg-blue-100;
    }
  }
}
