.resource-details-content.invoice-details-content {
    .back-button-container {
        @apply flex justify-start;
    }

    .header-container {
        @apply my-4 mx-2;

        .header-resource-title-container {
            @apply flex items-center gap-2;

            .header-resource-title {
                @apply text-2xl font-bold m-0;
            }
        }
    }

    .content-container {
        @apply w-full flex flex-col gap-4 border-t border-gray-300 mt-5 py-1;

        .content-container-row {
            @apply w-full flex flex-row gap-4;

            > * {
                @apply w-full;
            }
        }

        .invoice-info-card {
            @apply rounded bg-white shadow;

            &.invoice-status-container {
                @apply w-full;

                .invoice-info-card-content {
                    @apply pb-0;

                    .invoice-info-card-row {
                        .label {
                            @apply text-gray-700 w-full;

                            span {
                                @apply font-bold;
                            }
                        }
                    }
                }
            }

            .invoice-info-card-header {
                @apply flex justify-between items-center gap-2 px-5 py-3 border-b border-gray-300;

                .header-title {
                    @apply text-xl font-bold;
                }
            }

            .invoice-info-card-content {
                @apply pb-2 divide-y divide-gray-300;

                .invoice-info-card-row {
                    @apply flex justify-between items-center px-5 py-2;
                    min-width: 20rem;

                    & > p,
                    & > div {
                        &.label {
                            @apply w-1/2 text-sm text-gray-500;
                        }

                        &.value {
                            @apply w-1/2 font-medium;
                        }
                    }
                }

                .invoice-payments-section {
                    @apply pt-4;

                    button {
                        @apply ml-4 mt-2;
                    }
                }
            }
        }

        .invoice-entries-container {
            @apply w-full;

            .buttons-container {
                @apply flex items-center gap-2;
            }
        }
    }
}
