.empowerment-preview-page-container {
    height: calc(100vh - 18.5rem);

    .empowerment-preview-page-header {
        @apply flex justify-end items-center my-4;

        .header-buttons-container {
            @apply flex items-center gap-2;
        }
    }

    .empowerment-preview-container {
        @apply w-full h-full py-4;
    }

    .no-file {
        @apply text-sm text-center font-semibold text-gray-500 py-4;
    }
}
