.details-card {
    @apply h-full rounded bg-white shadow;

    .header {
        @apply flex justify-between items-center px-5 py-3 border-b border-gray-300;

        .header-title {
            @apply text-xl font-bold;
        }
    }

    .details-card-content {
        @apply pb-2 divide-y divide-gray-300;

        .details-card-row {
            @apply flex justify-between items-center px-5 py-2;

            & > p {
                &.label {
                    @apply w-1/3 text-sm text-gray-500;
                }

                &.value {
                    @apply w-2/3 font-medium;
                }
            }
        }
    }

    .center-loader {
        @apply h-full pt-10 flex justify-center;
    }
}
