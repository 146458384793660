.page-subscription-show {
    @apply w-full py-4 space-y-4;

    .parties-appeals-hearings-container {
        @apply h-full flex gap-4;

        .parties-appeals-container {
            @apply w-2/5 shrink-0;
        }

        .hearings-container {
            height: calc(100vh - 12.5rem);
        }
    }
}

.resource-details-content {
    @apply h-content;

    .back-button-container {
        @apply flex justify-start;
    }

    .header-resource-title-container {
        @apply flex items-center gap-2;

        .header-resource-title {
            @apply text-2xl font-bold my-4 mx-2;
        }

        .subscription-active-badge-container {
            @apply py-1 px-3 rounded-lg;

            .subscription-active-badge-text {
                @apply text-xs font-bold;
            }

            &.subscription-active {
                @apply bg-avo-green;

                .subscription-active-badge-text {
                    @apply text-white;
                }
            }

            &.subscription-inactive {
                @apply border border-avo-red;

                .subscription-active-badge-text {
                    @apply text-avo-red;
                }
            }
        }
    }
}
