.page-info {
    .custom-invoice-payments-header {
        @apply mt-5 flex justify-end items-baseline;

        .total-filtered-container {
            @apply font-medium text-sm text-gray-900;

            .total-sum {
                @apply ml-1 font-bold;
            }
        }
    }

    .payment-canceled {
        @apply line-through;
    }
}
