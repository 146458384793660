.calendar-page-container {
    @apply flex flex-col h-content;

    .calendar-page-header {
        @apply relative flex flex-none items-center justify-between border-b border-gray-200 py-4 z-10;

        .selected-date {
            @apply capitalize text-lg font-semibold text-gray-900;
        }
    }

    .calendar-container {
        @apply h-full;
    }
}
