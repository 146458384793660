.invoice-payments-custom-header-container {
    @apply pt-2;

    > p {
        @apply font-normal text-sm text-gray-700 leading-5;

        span {
            @apply font-bold;
        }
    }
}

.content-container {
    .payment-canceled {
        @apply line-through;
    }
}
