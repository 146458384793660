.person-info {
    .person-name {
        @apply font-bold text-2xl;
    }

    .info-type {
        @apply font-bold mb-2;
    }

    .left,
    .company-data,
    .other-companies,
    .documents {
        @apply border border-gray-700 py-2 px-4;
    }

    .info-container {
        width: calc(theme("width[full]") + 16px);
        @apply flex flex-wrap -mx-2;

        .left,
        .right {
            width: calc(theme("width[1/2]") + 16px);
            @apply m-2;
        }

        .right {
            .other-companies {
                @apply mt-4;

                .companies-list {
                    @apply max-h-52 overflow-auto;
                }
            }
        }
    }

    .documents {
        @apply mt-2;
    }
}
