.resource-details-content {
    .back-button-container {
        @apply flex justify-start;
    }

    .header-container {
        @apply my-4 mx-2;

        .header-resource-title-container {
            @apply flex items-center gap-2;

            .header-resource-title {
                @apply text-2xl font-bold m-0;
            }
        }
    }

    .add-invoice-form-container {
        @apply space-y-4;

        .add-invoice-form-row {
            @apply flex items-center gap-2;

            & > * {
                @apply w-full;
            }

            &.asym-split {
                & > *:first-child {
                    @apply w-2/3;
                }

                & > *:nth-child(2) {
                    @apply w-1/3;
                }
            }
        }

        .has-error {
            input.avo-input[type="time"] {
                @apply text-avo-red;
            }
        }

        .time-error-message {
            @apply p-0.5 text-sm font-medium text-avo-red;
        }

        .interval-picker-container {
            .interval-picker-label {
                @apply block text-sm font-medium text-gray-700 mb-1;
            }

            .interval-datepickers-container {
                @apply flex items-center gap-2;
            }
        }
    }

    .totals-section {
        @apply flex items-start align-top justify-between;

        .buttons-container {
            @apply w-1/2 flex gap-2;
        }
        .total-texts-container {
            @apply w-1/3;

            .total-text {
                @apply font-medium text-sm;

                span {
                    @apply font-bold;
                }
            }
        }
    }
}
