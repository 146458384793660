.tasks-list-datatable-task-title-column,
.tasks-list-datatable-action-buttons-column {
    @apply flex items-center gap-2;
}

.page-info-extended {
    @apply flex flex-col gap-3;

    .page-filters-container {
        @apply bg-gray-50 max-h-full shadow overflow-hidden sm:rounded-lg pt-4 pb-2;

        .filters-header {
            @apply flex flex-row justify-between mx-5 mb-3;

            .table-title {
                @apply text-xl font-bold mr-2;
            }
        }

    }
}