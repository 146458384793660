.notification-details-container {
    @apply h-full flex flex-col justify-between;

    .notification-details-texts-container {
        .notification-details-changes-text {
            @apply font-bold text-gray-700 mb-2;
        }

        .notification-details-message {
            @apply text-lg text-gray-700;

            &.litigation-number-text {
                @apply font-bold text-gray-700 mt-4;
            }

            &.party-text {
                @apply font-bold ml-2;
            }

            &.party-value {
                @apply ml-4;
            }
        }
    }
}
