.edit-invoice-entry-form-container {
    @apply space-y-4;

    .autocomplete-invoice-entry-name-section {
        > p {
            @apply text-sm font-medium text-gray-700 mb-1;
        }

        .autocomplete-buttons-row {
            @apply flex gap-2 items-center pb-2;
        }
    }

    .edit-invoice-entry-form-row {
        @apply flex justify-between gap-4;

        > * {
            @apply w-full;
        }
    }
}
