.page-client-show {
    @apply w-full py-4;

    .row {
        @apply w-full flex flex-wrap justify-between gap-2;

        .left,
        .right {
            width: calc(theme("width[1/2]") - 8px);
        }
    }
}

.resource-details-content {
    .back-button-container {
        @apply flex justify-start;
    }

    .header-resource-title-container {
        @apply flex items-center gap-2;

        .header-resource-title {
            @apply text-2xl font-bold my-4 mx-2;
        }

        .client-active-badge-container {
            @apply py-1 px-3 rounded-lg;

            .client-active-badge-text {
                @apply text-xs font-bold;
            }

            &.client-active {
                @apply bg-avo-green;

                .client-active-badge-text {
                    @apply text-white;
                }
            }

            &.client-inactive {
                @apply border border-avo-red;

                .client-active-badge-text {
                    @apply text-avo-red;
                }
            }
        }
    }
}
