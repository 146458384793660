.avo-invoice-add-products-section {
    .add-invoice-entry-form-container {
        @apply w-full py-2 mb-2;

        .autocomplete-invoice-entry-name-section {
            @apply flex gap-2 items-center pb-2;

            > p {
                @apply text-sm font-medium text-gray-700;
            }
        }

        .add-invoice-entry-row {
            @apply grid grid-cols-12  gap-2;

            > * {
                @apply col-span-6 lg:col-span-1;

                &:first-child {
                    @apply col-span-12 lg:col-span-5;
                }

                &:nth-last-child(2) {
                    @apply col-span-6 lg:col-span-2;
                }

                &:last-child {
                    @apply col-span-12 mt-2 lg:col-span-1 lg:mt-0;
                }
            }

            &.exchange-rate-container {
                @apply mt-2;

                > * {
                    &:first-child {
                        @apply col-span-6 lg:col-span-3 lg:col-start-7;
                    }

                    &:last-child {
                        @apply col-span-6 lg:col-span-3 mt-0;
                    }
                }
            }

            .invoice-product-form-small-button-container {
                @apply hidden lg:grid lg:place-items-end;
            }
        }

        .invoice-product-form-large-button-container {
            @apply grid lg:hidden mt-2;
        }
    }
}
