.select-task-modal-container {
    @apply flex flex-col py-4 px-1;

    .select-task-modal-header {
        @apply flex justify-between items-center pb-3;

        .select-task-modal-title {
            @apply text-xl font-bold;
        }
    }

    .selects-container {
        .select-project-container {
            @apply mb-4;
        }

        .search-tasks-container {
            @apply py-2;
        }

        .tasks-container {
            @apply h-56  overflow-scroll border border-gray-200 p-2;

            .loading-tasks-container {
                @apply flex items-center justify-center py-2;
            }
        }
    }

    .no-projects-container {
        @apply pt-4;

        .no-projects-text {
            @apply text-lg mb-6;
        }
    }

    .task-selected-action-container {
        @apply mt-8;

        .time-log-method {
            @apply text-sm font-medium;
        }

        .buttons-container {
            @apply mt-3 flex gap-2;
        }
    }
}
