.info-notes-container {
    @apply bg-white shadow rounded-lg sm:overflow-hidden;

    .info-notes-inner-container {
        @apply divide-y divide-gray-200;
    }

    .title-container {
        @apply flex items-center px-4 py-5 sm:px-6;

        .title {
            @apply text-lg font-medium text-gray-900 mr-5;
        }
    }

    .notes-container {
        @apply px-4 py-6 sm:px-6;

        .no-notes-text {
            @apply text-sm text-gray-700;
        }

        .notes-list {
            @apply space-y-8;

            .note-container {
                @apply text-sm font-medium;

                .note-user-name {
                    @apply text-gray-900;
                }

                .note-body {
                    @apply mt-1 font-normal text-gray-700;
                }

                .note-footer-container {
                    @apply flex items-baseline mt-2 space-x-2 text-sm;

                    .note-date {
                        @apply text-gray-500;
                    }

                    .delete-note-button {
                        @apply p-0;
                    }
                }
            }
        }
    }

    .add-note-form-container {
        @apply bg-gray-50 px-4 py-6 sm:px-6;

        .note-textarea {
            @apply shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md;
        }

        .button-container {
            @apply mt-3 flex items-center justify-end;
        }
    }
}
