.avo-file-type-badge {
    @apply text-xs text-center leading-4 font-medium rounded;
    padding: 2px 10px;

    &.primary {
        @apply bg-blue-100 text-primary;
    }

    &.secondary {
        @apply bg-green-100 text-avo-green;
    }

    &.yellow {
        @apply bg-yellow-100 text-yellow-800;
    }

    &.purple {
        @apply bg-purple-100 text-purple-800;
    }

    &.red {
        @apply bg-red-100 text-red-800;
    }
}
