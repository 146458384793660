.start-1 {
    @apply col-start-1 sm:col-start-1;
}

.start-3 {
    @apply col-start-1 sm:col-start-3;
}

.start-5 {
    @apply col-start-1 sm:col-start-5;
}

.start-7 {
    @apply col-start-1 sm:col-start-7;
}

.start-9 {
    @apply col-start-1 sm:col-start-9;
}

.start-11 {
    @apply col-start-1 sm:col-start-11;
}

.start-12 {
    @apply col-start-1 sm:col-start-12;
}
