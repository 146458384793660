.billable-icon {
    @apply h-7 w-7;

    &.green {
        @apply text-avo-green;
    }

    &.red {
        @apply text-avo-red;
    }
    &.yellow {
        @apply text-avo-orange;
    }
}