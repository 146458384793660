.reset-entries-info-modal {
    @apply text-center;

    .message {
        @apply text-lg;

        span {
            @apply font-bold;
        }
    }

    .buttons-container {
        @apply w-full flex justify-end gap-6 mt-6;
    }
}
