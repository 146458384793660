.edit-task-form-container {
    @apply space-y-4;

    .edit-task-form-row {
        @apply flex gap-2;

        & > * {
            @apply w-full;
        }
    }

    .disclaimer-text {
        @apply block text-sm text-gray-500 mb-1;

        span {
            @apply font-bold text-gray-700;
        }
    }

    .interval-picker-container {
        .interval-picker-label {
            @apply block text-sm font-medium text-gray-700 mb-1;
        }

        .interval-datepickers-container {
            @apply flex items-center gap-2;
        }
    }
}
