.page-project-show {
    @apply w-full py-4;

    .row {
        @apply w-full flex flex-wrap justify-between gap-2;

        &:not(:first-child) {
            @apply mt-4;
        }

        .left,
        .right {
            width: calc(theme("width[1/2]") - 8px);
        }
    }

    .hearings-container {
        height: calc(100vh - 18.5rem);
    }
}

.resource-details-content {
    .back-button-container {
        @apply flex justify-start;
    }

    .header-resource-title-container {
        @apply flex items-center gap-2;

        .header-resource-title {
            @apply text-2xl font-bold my-4 mx-2;
        }

        .project-active-badge-container {
            @apply py-1 px-3 rounded-lg;

            .project-active-badge-text {
                @apply text-xs font-bold;
            }

            &.project-active {
                @apply bg-avo-green;

                .project-active-badge-text {
                    @apply text-white;
                }
            }

            &.project-inactive {
                @apply border border-avo-red;

                .project-active-badge-text {
                    @apply text-avo-red;
                }
            }
        }
    }
}

.log-details-card {
    .log-details-card-content {
        @apply p-5;
    }
}
