.auto-stopper-task-time-log-modal-content-container {
    .auto-stopped-task-time-log-message {
        @apply text-base font-semibold text-gray-900 text-center;

        span {
            @apply font-bold;
        }
    }

    .auto-stopped-task-time-log-disclaimer {
        @apply text-sm text-gray-500 text-center mt-2;
    }

    .auto-stopper-task-time-log-modal-buttons-container {
        @apply w-full flex justify-between gap-2 mt-8;

        > * {
            @apply w-full;
        }
    }
}
