.history-container {
    @apply bg-white px-4 py-5 shadow rounded-lg sm:px-6;

    .history-page-title-container {
        @apply flex justify-between items-center gap-5;

        .page-title {
            @apply text-lg font-medium text-gray-900;
        }
    }

    .logs-list-container {
        @apply mt-6 flow-root;

        .logs-list {
            @apply -mb-8;
        }
    }

    .button-container {
        @apply mt-6 w-full;
    }
}
