.dashboard-container {
    @apply grid grid-cols-2 gap-6 lg:grid-cols-4 py-4;

    .dashboard-card-container {
        @apply col-span-2 flex flex-col rounded-lg bg-white shadow;

        .dashboard-card-content-container {
            @apply flex flex-1 flex-col p-8;

            .dashboard-card-title {
                @apply text-xl font-medium leading-8 text-gray-900;
            }

            .dashboard-card-description {
                @apply text-sm text-gray-500;
            }
        }
    }
}
