.toggle-label-container {
    @apply flex items-center;

    .toggle-label {
        @apply mr-3 text-sm font-medium text-gray-900;
    }

    .toggle-container {
        @apply relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transform transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-red bg-avo-red;

        .toggle {
            @apply transform pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200 translate-x-0;

            .dot-container {
                @apply absolute inset-0 h-full w-full flex items-center justify-center transform transition-opacity;

                .dot {
                    @apply h-3 w-3;
                }

                &.dot-disabled-container {
                    @apply opacity-100 ease-in duration-200;

                    .dot {
                        @apply text-avo-red;
                    }
                }

                &.dot-checked-container {
                    @apply opacity-0 ease-out duration-100;
                }
            }
        }

        &.checked {
            @apply bg-avo-green focus:ring-avo-green;

            .toggle {
                @apply translate-x-5;

                .dot-container {
                    &.dot-disabled-container {
                        @apply opacity-0 ease-out duration-100;
                    }

                    &.dot-checked-container {
                        @apply opacity-100 ease-in duration-200;

                        .dot {
                            @apply text-avo-green;
                        }
                    }
                }
            }
        }
        &.disabled {
            @apply bg-gray-200;
        }
    }

    &.full-width {
        @apply w-full flex justify-between;
    }
}
