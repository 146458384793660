.category-navbar {
    @apply flex h-10 mb-1 border border-gray-700;

    a {
        @apply no-underline;

        .category-button {
            @apply h-full flex justify-center content-center pt-0 pb-0 pr-6 pl-6 border-r border-gray-700;

            &:hover {
                @apply bg-gray-500;
            }

            .category-name {
                @apply text-base font-semibold text-black;
            }

            &.active {
                @apply bg-gray-500;
            }
        }
    }

    &.sm {
        @apply h-5 border-0;

        .category-button {
            @apply pt-0 pb-0 pl-4 pr-4 border-r-0 rounded;
        }
    }
}
