.page-empowerment-show {
    @apply w-full py-4;

    .row {
        @apply w-full flex flex-wrap justify-between gap-2;

        .left,
        .right {
            width: calc(theme("width[1/2]") - 8px);
        }
    }
}

.resource-details-content {
    .back-button-container {
        @apply flex justify-start;
    }

    .header-resource-title-container {
        @apply flex items-center gap-2;

        .empowerment-name-container {
            @apply flex items-center gap-4;

            .header-resource-title {
                @apply text-2xl font-bold;
            }
        }

        .loader-container {
            @apply justify-start p-4;
        }
    }
}
