.invoice-activity-report-custom-header-container {
    @apply pt-4;

    > p {
        @apply text-sm font-medium text-gray-500;
    }

    &-totals {
        @apply pt-2;

        > p {
            @apply font-normal text-sm text-gray-700 leading-5;

            span {
                @apply font-bold;
            }
        }
    }
}
