.page-info {
    .custom-invoices-header {
        @apply mt-5 flex justify-between items-baseline;

        .total-filtered-container {
            @apply font-medium text-sm text-gray-900;

            .total-sum {
                @apply ml-1 font-bold;
            }
        }
    }

    .invoice-cell {
        &.invoice-canceled {
            @apply line-through;
        }
        svg {
            margin: auto;
        }
    }
    .table-cell {
        svg {
            margin: auto;
        }
    }
}
