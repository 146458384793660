.add-bpi-subscription-modal-content {
    @apply flex gap-4 justify-center divide-x;

    height: 650px;

    > * {
        @apply px-4;
    }

    .section-title {
        @apply text-lg leading-6 font-medium text-center text-gray-900;
    }

    .search-data-sources-container {
        @apply w-1/2;

        .project-select {
            @apply mt-4
        }

        .search-data-sources-form {
            @apply py-4 space-y-4;
        }
    }

    .results-list-container {
        @apply w-1/2 h-full flex flex-col py-4;

        .companies-list-empty {
            @apply text-center text-gray-400 py-4;
        }

        .companies-list {
            @apply h-full overflow-auto space-y-4 my-2 pr-2;

            .company-card {
                @apply w-full border rounded-md shadow-sm hover:cursor-pointer;

                .card-header {
                    @apply p-4 border-b;

                    .card-title {
                        @apply font-bold text-base text-primary;
                    }
                }

                .card-content {
                    .card-content-child {
                        @apply flex gap-1 px-4 py-1;

                        &:nth-of-type(2n) {
                            @apply bg-gray-200;
                        }

                        .title {
                            @apply text-gray-500;
                        }

                        .subtitle {
                            @apply font-medium line-clamp-1 text-gray-900;
                        }
                    }
                }
            }
        }
    }
}
