.avo-calendar-pagination-container {
    @apply flex items-center rounded-md shadow-sm md:items-stretch bg-white;

    .avo-calendar-pagination-button {
        @apply md:w-12 flex items-center justify-center border border-gray-300 bg-white py-2 md:px-2 text-gray-400 hover:text-gray-500  md:hover:bg-gray-50;

        .avo-calendar-pagination-button-icon {
            @apply h-5 w-5;
        }

        &.previous {
            @apply rounded-l-md border-r-0 pl-3 pr-4;
        }

        &.next {
            @apply rounded-r-md border-l-0 pl-4 pr-3;
        }
    }

    .avo-calendar-pagination-today-button {
        @apply hidden md:block border-t border-b border-gray-300 bg-white px-5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900;
    }

    .avo-calendar-pagination-buttons-divider {
        @apply md:hidden -mx-px h-5 w-px bg-gray-300;
    }
}
