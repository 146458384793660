.avo-calendar-day-view-container {
    @apply lg:grid lg:grid-cols-12 lg:gap-x-16;

    .avo-calendar-day-view-left-container {
        @apply mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9;

        .avo-calendar-day-view-calendar-pagination-container {
            @apply flex items-center text-gray-900;

            .avo-calendar-day-view-calendar-pagination-button {
                @apply -m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500;

                .avo-calendar-day-view-calendar-pagination-button-icon {
                    @apply h-5 w-5;
                }
            }

            .avo-calendar-day-view-calendar-pagination-month {
                @apply flex-auto font-semibold;
            }
        }

        .avo-calendar-day-view-calendar-weekdays-container {
            @apply mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500;
        }

        .avo-calendar-day-view-calendar-container {
            @apply isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200;

            .avo-calendar-day-view-calendar-calendar-day-button {
                @apply text-gray-400 py-1.5 bg-gray-50 hover:bg-gray-100 focus:z-10;

                .avo-calendar-day-view-day {
                    @apply mx-auto flex h-7 w-7 items-center justify-center rounded-full;
                }

                &.is-current-month {
                    @apply text-gray-900 bg-white;
                }

                &.is-today {
                    @apply font-semibold text-primary;
                }

                &.is-selected {
                    @apply font-semibold text-white;

                    .avo-calendar-day-view-day {
                        @apply bg-gray-900;
                    }

                    &.is-today {
                        .avo-calendar-day-view-day {
                            @apply bg-primary;
                        }
                    }
                }
            }
        }
    }

    .avo-calendar-day-view-tasks-list {
        @apply mt-4 divide-y divide-gray-300 text-sm leading-6 lg:col-span-7 xl:col-span-8;

        .avo-calendar-day-view-task-container {
            @apply relative flex items-center space-x-6 py-6 xl:static;

            .avo-calendar-day-view-task-inner-container {
                @apply flex-auto;

                .avo-calendar-day-view-task-name-container {
                    @apply flex items-center gap-2 pr-10 font-semibold text-gray-900 xl:pr-0 hover:underline;
                }

                .avo-calendar-day-view-task-description-container {
                    @apply mt-2 flex flex-col text-gray-500 xl:flex-row;

                    .avo-calendar-day-view-task-description-inner-container {
                        @apply flex items-start space-x-3;

                        .avo-calendar-day-view-task-calendar-icon-container {
                            @apply mt-0.5;

                            .calendar-icon {
                                @apply h-5 w-5 text-gray-400;
                            }
                        }
                    }
                }
            }
        }
    }
}
