.tooltip-wrapper {
  @apply relative flex items-center;

  .tooltip-container {
    @apply mb-2 ml-2 px-2 py-1 shadow-lg max-w-96;
    @apply bg-gray-700 text-white text-sm rounded-lg;
    @apply absolute bottom-full z-10 transform -translate-x-1/2;

    .tooltip-point {
      @apply absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-1 w-3 h-3 bg-gray-700 rotate-45
    }
  }
}