.log-row {
    @apply relative pb-8;

    .log-icon-divider {
        @apply absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200;
    }

    .log-container {
        @apply relative flex space-x-3;

        .log-icon-container {
            @apply text-white;

            .log-icon {
                @apply h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white;

                svg {
                    @apply h-5 w-5;
                }
            }
        }

        .log-info-container {
            @apply min-w-0 flex-1 pt-1.5 flex justify-between space-x-4;

            .log-text {
                @apply text-sm text-gray-500;

                .log-person,
                .log-text-span {
                    @apply font-medium text-gray-900;
                }

                .log-field-name-span {
                    @apply underline;
                }
            }

            .log-date {
                @apply text-right text-sm whitespace-nowrap text-gray-500;
            }

            .multi-change-container {
                .multi-change-text {
                    @apply text-sm text-gray-500;
                }

                .log-texts-list {
                    @apply px-4;
                }
            }
        }
    }
}

// .empowerment-history-card {
//     .content {
//         @apply py-4;

//         .logs-list-container {
//             @apply flow-root;

//             .logs-list {
//                 @apply -mb-8;
//             }
//         }
//     }
// }
