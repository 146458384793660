.hearings-container {
    @apply w-full flex flex-col gap-6  bg-gray-50 shadow rounded-lg p-4;

    .section-title-container {
        @apply flex justify-between items-center;

        .section-title {
            @apply text-xl font-bold mr-2;
        }
    }

    .hearings-list-container {
        @apply overflow-auto pr-4;

        .hearing-info-item-container {
            @apply relative flex gap-x-4;

            .hearing-info-item-line-container {
                @apply absolute left-0 top-0 flex w-6 justify-center -bottom-6;

                .hearing-info-item-line {
                    @apply w-px bg-gray-200;
                }
            }

            .hearing-info-item-dot-container {
                @apply relative flex h-6 w-6 flex-none items-center justify-center;

                .hearing-info-item-dot {
                    @apply h-1.5 w-1.5 rounded-full bg-white ring-1 ring-gray-300;
                }
            }

            .hearing-details-container {
                @apply flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white;

                .hearing-details-header {
                    @apply flex justify-between gap-x-4 items-start;

                    .hearing-time {
                        @apply font-medium text-gray-900;
                    }

                    .hearing-court {
                        @apply mt-0.5 text-gray-500;
                    }

                    .header-details-right-container {
                        @apply flex flex-col gap-2 items-end;

                        .hearing-phase {
                            @apply px-1 text-xs text-primary-dark font-bold uppercase border border-primary-dark rounded;
                        }

                        .header-edit-delete-container {
                            @apply flex gap-1;
                        }
                    }
                }

                .hearing-details-section {
                    @apply mt-2;

                    .hearing-detail-key {
                        @apply text-sm text-gray-700;
                    }
                }

                .hearing-detail-footer {
                    @apply w-full flex justify-end;
                }
            }
        }
    }

    .center-loader {
        @apply flex justify-center items-center pb-4;
    }
}
