.invoice-actions-buttons-container {
    @apply w-full flex justify-end gap-2 pt-2;

    .avo-dropdown-menu-item {
        &.disabled {
            @apply pointer-events-none cursor-default text-gray-300;
        }
    }

    button.avo-dropdown-menu-item {
        @apply w-full text-left;

        &:disabled {
            @apply pointer-events-none text-gray-300;
        }
    }
}
