.add-invoice-payment-form-container {
    @apply space-y-4;

    .invoice-remaining-sum-container {
        @apply pt-4;

        .invoice-remaining-sum {
            @apply text-sm font-medium text-gray-700;

            span {
                @apply font-bold text-gray-900;
            }
        }

        .amount-input-container {
            @apply w-1/2 flex gap-2 items-end;

            .amount-currency {
                @apply text-base font-bold py-2 text-gray-900;
            }
        }
    }
}
