.add-document-series-form-container {
    @apply space-y-6;

    .offset-split-row {
        @apply grid grid-cols-3 gap-6;

        > :nth-child(1) {
            @apply col-span-3  sm:col-span-1;
        }

        > :nth-child(2) {
            @apply col-span-3  sm:col-span-2;
        }

        &.flipped {
            > :nth-child(1) {
                @apply col-span-3  sm:col-span-2;
            }

            > :nth-child(2) {
                @apply col-span-3  sm:col-span-1;
            }
        }
    }

    .split-row {
        @apply grid grid-cols-2 gap-6;

        & > * {
            @apply col-span-2 lg:col-span-1 md:col-span-2 sm:col-span-1;
        }
    }

    .split-row-3 {
        @apply grid grid-cols-3 gap-3;
    }
}
