.avo-creatable-container {
    @apply flex flex-col;

    &.label-inline {
        @apply flex-row items-center gap-2;
    }

    .avo-creatable-label {
        @apply block mb-1 text-sm font-medium text-gray-700 whitespace-nowrap;
    }

    .avo-creatable {
        .avo-creatable__control {
            @apply text-sm rounded-md shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary focus-within:border-gray-300;
        }

        &.small {
            .avo-creatable__control {
                .avo-creatable__value-container {
                    @apply py-0 pl-1 pr-5 text-sm sm:text-xs;
                }
            }
        }

        &.medium {
            .avo-creatable__control {
                .avo-creatable__value-container {
                    @apply pl-3 pr-10 py-1 text-base sm:text-sm;
                }
            }
        }

        &.large {
            .avo-creatable__control {
                .avo-creatable__value-container {
                    @apply pl-5 pr-14 py-3 text-lg sm:text-base;
                }
            }
        }

        &.full-width {
            @apply w-full;
        }
    }
}
