.avo-color-picker-container {
    label.avo-label {
        @apply block text-sm font-medium text-gray-700 mb-1;
    }

    .avo-color-picker-select-container {
        @apply border border-gray-300 rounded-md shadow-sm py-2 px-3 hover:cursor-pointer;

        .avo-color-picker-preview-section {
            @apply flex items-center justify-between;

            .avo-color-picker-preview-container {
                @apply flex items-center gap-2;

                .avo-color-preview-color-hex {
                    @apply text-base sm:text-sm font-bold text-gray-600;
                }
            }

            .avo-color-picker-select-icons-container {
                @apply flex divide-x divide-gray-300;

                .avo-color-picker-select-icon {
                    @apply h-6 text-gray-400 pl-2;

                    &:not(:last-child) {
                        @apply pr-2;
                    }
                }
            }
        }
    }

    &.disabled {
        .avo-color-picker-color-select-container {
            @apply bg-gray-100;

            .avo-color-picker-preview-container {
                .avo-color-preview-color-hex {
                    @apply text-gray-400;
                }
            }
        }
    }
}
