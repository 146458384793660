.avo-dropdown-menu {
    @apply relative inline-block text-left;

    .avo-dropdown-menu-button {
        @apply inline-flex w-full justify-center rounded-md font-medium;

        &.contained {
            @apply text-white shadow-sm;

            &.primary {
                @apply bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
            }

            &.secondary {
                @apply bg-avo-green hover:bg-avo-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-green;
            }

            &.red {
                @apply bg-avo-red hover:bg-avo-red-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-red;
            }

            &.orange {
                @apply bg-avo-orange hover:bg-avo-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-orange;
            }

            &.gray {
                @apply bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
            }

            &:disabled {
                @apply bg-gray-300 hover:bg-gray-300 hover:cursor-default;
            }
        }

        &.text {
            &.primary {
                @apply bg-transparent text-primary hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
            }

            &.secondary {
                @apply bg-transparent text-avo-green hover:text-avo-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-green;
            }

            &.red {
                @apply bg-transparent text-avo-red hover:text-avo-red-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-red;
            }

            &.orange {
                @apply bg-transparent text-avo-orange hover:text-avo-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-orange;
            }

            &.gray {
                @apply bg-transparent text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
            }

            &:disabled {
                @apply text-gray-300 hover:text-gray-300 hover:cursor-default;
            }
        }

        &.outlined {
            @apply shadow-sm;

            &.primary {
                @apply border border-primary hover:border-primary-dark bg-transparent text-primary hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
            }

            &.secondary {
                @apply border border-avo-green hover:border-avo-green-dark bg-transparent text-avo-green hover:text-avo-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-green;
            }

            &.red {
                @apply border border-avo-red hover:border-avo-red-dark bg-transparent text-avo-red hover:text-avo-red-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-red;
            }

            &.orange {
                @apply border border-avo-orange hover:border-avo-orange-dark  bg-transparent text-avo-orange hover:text-avo-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avo-orange;
            }

            &.gray {
                @apply border border-gray-500 hover:border-gray-400 bg-transparent text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
            }

            &:disabled {
                @apply border-gray-300 hover:border-gray-300 text-gray-300 hover:text-gray-300  hover:cursor-default;
            }
        }

        &.small {
            @apply py-1 px-2 text-xs;
        }

        &.medium {
            @apply py-2 px-4 text-sm;
        }

        &.large {
            @apply py-4 px-6 text-base;
        }

        &.full-width {
            @apply w-full;
        }
    }

    .avo-dropdown-menu-items-container {
        @apply absolute right-0 bottom-3/4 z-10 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;

        .avo-dropdown-menu-item {
            @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:cursor-pointer hover:text-gray-900;
        }
    }
}
