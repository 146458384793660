.step-list-item {
    @apply relative overflow-hidden lg:flex-1;

    .step-container {
        @apply border border-gray-200 overflow-hidden lg:border-0;

        .step-bottom-line {
            @apply absolute top-0 left-0 w-1 h-full lg:w-full lg:h-1 lg:bottom-0 lg:top-auto bg-transparent;
        }

        .step-details-container {
            @apply px-6 py-4 flex items-center text-sm font-medium;

            &.first {
                @apply lg:pl-9;
            }

            .step-circle-container {
                @apply flex-shrink-0;

                .step-circle {
                    @apply w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full;

                    .step-complete-icon {
                        @apply w-6 h-6 text-white;
                    }

                    .step-number {
                        @apply text-sm font-medium text-gray-500;
                    }
                }
            }

            .step-texts {
                @apply ml-4 min-w-0;

                .step-name {
                    @apply text-sm font-semibold text-gray-500 tracking-wide uppercase;
                }
            }
        }

        &.current {
            .step-bottom-line {
                @apply bg-primary;
            }

            .step-details-container {
                .step-circle-container {
                    .step-circle {
                        @apply border-primary;
                    }
                }

                .step-texts {
                    .step-name {
                        @apply text-gray-500;
                    }
                }
            }
        }

        &.complete {
            .step-details-container {
                .step-circle-container {
                    .step-circle {
                        @apply border-avo-green bg-avo-green;
                    }
                }
            }
        }

        .step-separator {
            @apply hidden absolute top-0 left-0 w-3 inset-0 lg:block;

            .separator-svg {
                @apply h-full w-full text-gray-300;
            }
        }
    }
}
