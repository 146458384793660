.card-title {
  @apply text-primary font-bold text-sm
}

.card-row {
  @apply text-xs mt-0.5 text-gray-500 flex
}

.card-footer {
  @apply w-full flex justify-end
}