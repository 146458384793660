.dashboard-container {
    .dashboard-card-container {
        &.time-card {
            @apply col-span-1 items-center text-center;

            .dashboard-card-content-container {
                @apply items-center justify-center;

                .dashboard-card-title {
                    @apply my-2;
                }

                .time-card-activity-link {
                    @apply mt-4;
                }
            }
        }
    }
}
