.avo-color-preview {
    &.circle {
        @apply rounded-full;
    }

    &.square {
        @apply rounded-sm;
    }

    &.small {
        @apply h-4 w-4;
    }

    &.medium {
        @apply h-6 w-6;
    }

    &.large {
        @apply h-8 w-8;
    }
}
