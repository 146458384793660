.avo-document-dropzone {
    @apply w-full h-full flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded bg-gray-50 py-5;

    .upload-file-container {
        @apply flex flex-col justify-center items-center;

        .upload-file-text {
            @apply text-gray-400 text-sm leading-4 mt-2;
        }
    }

    .uploaded-file-preview-container {
        @apply w-full flex justify-between items-center px-2;

        .file-info {
            @apply flex items-center gap-2;

            .file-icon {
                @apply h-7 text-primary;
            }

            .file-name {
                @apply text-lg font-bold;
            }
        }
    }
}
