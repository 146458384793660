.task-time-log-container {
    @apply w-full flex flex-col justify-between p-1 rounded  hover:border border-gray-500 hover:cursor-pointer bg-gray-50;

    .task-project-container {
        .task-project-name {
            @apply text-xs line-clamp-2 text-gray-500;
        }

        .task-name {
            @apply text-sm line-clamp-3 font-bold text-gray-700 leading-3;
        }
    }

    .time-container {
        @apply flex items-end justify-between;

        .start-stop {
            @apply text-xxs;
        }

        .diff-time {
            @apply text-xs font-bold text-gray-700;
        }
    }
}
