.slideover-form-container {
    @apply h-full;

    .slideover-form {
        height: calc(theme("height[full]") - theme("height[12]"));

        @apply space-y-6 px-2 overflow-y-auto;

        .interval-picker-container {
            .interval-picker-label {
                @apply block text-sm font-medium text-gray-700 mb-1;
            }

            .interval-datepickers-container {
                @apply flex items-center gap-2;
            }
        }
    }

    .buttons-container {
        @apply h-12 flex items-end;
    }
}

.loader-container {
    @apply w-full h-full grid place-items-center;
}
