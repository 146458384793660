.signed-contract-preview-page-container {
    height: calc(100vh - 18.5rem);

    .signed-contract-preview-page-header {
        @apply my-4;

        .header-buttons-container {
            @apply flex items-center justify-end gap-2;
        }
    }

    .signed-contract-preview-container {
        @apply w-full h-full py-4;
    }

    .no-signed-contract {
        @apply text-sm font-semibold text-center text-gray-500 py-4;
    }
}
