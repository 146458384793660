.time-tracker-container {
    @apply grid grid-cols-7 gap-2 h-full w-80 p-2;

    .side-container {
        @apply h-full grid place-items-center;

        .side-icon {
            @apply h-7 w-7 text-gray-700 hover:cursor-pointer;
        }
    }

    .center-container {
        @apply h-full flex justify-center items-center col-span-5 border rounded-sm border-gray-700 hover:cursor-pointer;

        .select-task {
            @apply text-center font-medium;
        }

        .task-timer-container {
            @apply flex w-full;

            .task-container {
                @apply w-4/6 px-2;

                .task-title,
                .select-task {
                    @apply text-center text-xs font-semibold truncate;
                }
            }

            .timer-container {
                @apply w-2/6 border-l border-gray-700;
            }
        }
    }
}
