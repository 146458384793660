.client-group-details-container {
    .back-button-container {
        @apply flex justify-start;
    }

    .client-group-details-header-container {
        @apply flex items-center gap-2;

        .client-group-details-header-title {
            @apply text-2xl font-bold my-4 mx-2;
        }
    }
}
