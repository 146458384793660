.reports-filters-form-container {
  @apply space-y-4;
  margin-bottom: 20px;

  .filters-form-row {
    @apply flex items-center gap-2;

    & > * {
      @apply w-full;
    }

    &.asym-split {
      & > *:first-child {
        @apply w-2/3;
      }

      & > *:nth-child(2) {
        @apply w-1/3;
      }
    }
  }
}