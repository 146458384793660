.document-series-settings {
    @apply mt-4;

    .document-series-tables-container {
        @apply grid xl:grid-cols-2 gap-2;

        .document-series-name-cell-container {
            @apply flex items-center gap-2;

            .default-series-icon {
                @apply h-5 text-avo-green;
            }
        }
    }
}
