.account-activation-container {
    @apply flex justify-center min-h-screen bg-gray-100 antialiased;

    .main-container {
        @apply container sm:mt-40 mt-24 my-auto max-w-md border-2 border-gray-200 p-3 bg-white;

        .header {
            @apply text-center p-6;

            .logo {
                @apply h-10 w-auto m-auto mb-3;
            }

            .title {
                @apply text-3xl font-semibold text-gray-700;
            }
        }

        .content {
            @apply grid place-items-center m-6 mt-0;

            .account-activation {
                @apply grid place-items-center;

                .activation-message {
                    @apply text-lg text-center mt-3 mb-5;

                    span {
                        @apply text-xl font-bold;
                    }
                }

                .login-link {
                    @apply text-primary hover:text-primary-dark;
                }
            }
        }
    }
}
