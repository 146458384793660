.forgot-password-container {
    @apply flex justify-center min-h-screen bg-gray-100 antialiased;

    .main-container {
        @apply container sm:mt-40 mt-24 my-auto max-w-md border-2 border-gray-200 p-3 bg-white;

        .header {
            @apply text-center p-6;

            .logo {
                @apply h-10 w-auto m-auto mb-3;
            }

            .title {
                @apply text-3xl font-semibold text-gray-700;
            }

            p {
                @apply mt-1 text-gray-500;
            }
        }

        .back-to-login-container {
            @apply mt-1;

            .back-to-login-link {
                @apply text-xs font-medium text-gray-500 hover:text-black;
            }
        }
    }
}
