.form-section-container {
    height: calc(theme("height[content]") - theme("height[18]"));
    @apply max-w-4xl m-auto;

    &.auto-height {
        @apply h-auto;
    }

    .section-info-container {
        @apply md:col-span-1;

        .section-title {
            @apply text-lg font-medium leading-6 text-black;
        }

        .section-description {
            @apply mt-1 text-sm text-gray-500;
        }
    }

    .form-container {
        @apply h-full overflow-y-auto flex flex-col justify-between mt-5 md:mt-0 md:col-span-2 bg-white shadow rounded-md;

        .inner {
            @apply h-full overflow-y-auto px-4 py-5 space-y-6 sm:p-6;
        }

        .form-buttons-container {
            @apply flex justify-end gap-2 px-4 py-5;
        }
    }
}
