.avo-calendar-month-view-outer-container {
    @apply lg:flex lg:h-full lg:flex-col;

    .avo-calendar-month-view-container {
        @apply shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col;

        .avo-calendar-month-view-header-container {
            @apply grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none;

            .avo-calendar-month-view-header-day-container {
                @apply bg-white py-2;

                span {
                    @apply sr-only sm:not-sr-only;
                }
            }
        }

        .avo-calendar-month-view-calendar-container {
            @apply flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto;

            .avo-calendar-month-view-large-calendar-container {
                @apply w-full grid grid-cols-7 gap-px;

                &.rows-5 {
                    @apply grid-rows-5;
                }

                &.rows-6 {
                    @apply grid-rows-6;
                }

                .avo-calendar-month-view-day-container {
                    @apply bg-gray-50 text-gray-500 relative py-2;

                    &.is-current-month {
                        @apply bg-white;
                    }

                    .avo-calendar-month-view-day-number {
                        &.today {
                            @apply flex h-6 w-6 items-center justify-center rounded-full bg-primary font-semibold text-white;
                        }
                    }

                    .avo-calendar-month-view-day-events-list {
                        @apply mt-2;

                        .avo-calendar-month-view-event-link {
                            @apply flex px-2 rounded mb-1 mx-3;

                            .avo-calendar-month-view-event-title {
                                @apply flex-auto truncate font-medium text-gray-900 group-hover:text-primary;
                            }

                            .avo-calendar-month-view-event-time {
                                @apply ml-3 hidden flex-none text-gray-500 group-hover:text-primary lg:block;
                            }

                            .avo-calendar-month-view-more-text {
                                @apply text-gray-500;
                            }

                            &.avo-event-middle {
                                @apply mx-0 rounded-none;
                            }

                            &.avo-event-start {
                                @apply mx-0 rounded-r-none;
                            }

                            &.avo-event-stop {
                                @apply ml-0 rounded-l-none;
                            }
                        }
                    }
                }
            }
        }
    }
}
