.tag-container {
    @apply inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-avo-orange text-white;

    .tag-close-button {
        @apply flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white hover:bg-avo-orange-light focus:outline-none focus:bg-avo-orange-light;

        .close-icon {
            @apply h-3 w-3;
        }
    }
}
