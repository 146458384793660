.client-add-form-container {
    @apply h-content flex flex-col justify-between items-stretch -mt-6;

    .offset-split-row {
        @apply grid grid-cols-3 gap-6;

        > :nth-child(1) {
            @apply col-span-3  sm:col-span-2;
        }

        > :nth-child(2) {
            @apply col-span-3  sm:col-span-1;
        }

        &.flipped {
            > :nth-child(1) {
                @apply col-span-3  sm:col-span-1;
            }

            > :nth-child(2) {
                @apply col-span-3  sm:col-span-2;
            }
        }
    }

    .split-row {
        @apply grid grid-cols-2 gap-6;

        & > * {
            @apply col-span-2 lg:col-span-1 md:col-span-2 sm:col-span-1;
        }
    }

    .form-section-title {
        @apply text-base font-medium text-gray-900 mt-0;
    }

    .address-form-section,
    .bank-account-section {
        @apply space-y-6 pt-6;

        .address-container {
            @apply grid grid-cols-6 gap-6;

            & > * {
                @apply col-span-6 lg:col-span-2 md:col-span-6 sm:col-span-2;
            }
        }
    }

    .legal-representative {
        .buttons-container {
            @apply flex justify-end;
        }
    }
}
