.dashboard-container {
    .dashboard-card-container {
        &.clock-dashboard-card {
            .dashboard-card-content-container {
                @apply flex-row justify-between;

                .dashboard-card-title {
                    @apply text-2xl;
                }

                .dashboard-card-description {
                    @apply mt-6;
                }
            }
        }
    }
}
