@import "../../../../../../assets/scss/calendars";

.avo-week-calendar-outer-container {
    @apply h-full overflow-auto flex flex-auto flex-col bg-white;

    .avo-week-calendar-inner-container {
        @apply h-full flex flex-none flex-col max-w-full sm:max-w-none md:max-w-full;

        .avo-week-calendar-header-container {
            @apply sticky top-0 flex-none bg-white shadow ring-1 ring-black ring-opacity-5;

            .avo-week-calendar-header-day-container {
                @apply flex items-center py-3;

                .avo-week-calendar-header-day-number {
                    @apply items-center justify-center font-semibold text-gray-900;

                    &.is-today {
                        @apply text-primary;
                    }
                }

                .selected-container {
                    @apply flex items-baseline;

                    .avo-week-calendar-header-day-number {
                        @apply ml-1.5 flex h-8 w-8 rounded-full bg-primary text-white;
                    }
                }
            }

            .avo-week-calendar-mobile-header {
                @apply grid sm:hidden grid-cols-7 text-sm leading-6 text-gray-500;

                .avo-week-calendar-header-day-container {
                    @apply flex-col pt-2 pb-3;

                    .avo-week-calendar-header-day-number {
                        @apply flex h-8 w-8 mt-1;

                        &.is-selected {
                            @apply h-8 w-8 flex justify-center rounded-full bg-primary text-white;
                        }
                    }
                }
            }

            .avo-week-calendar-desktop-header {
                @apply -mr-px hidden sm:grid grid-cols-12 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500;

                .avo-week-calendar-header-day-container {
                    @apply justify-center;
                }
            }
        }

        .avo-week-calendar-body-container {
            @apply flex flex-auto;

            .avo-week-calendar-body {
                @apply grid flex-auto grid-cols-1 grid-rows-1;

                .avo-week-calendar-body-day-container {
                    @apply hidden sm:grid sm:grid-cols-12 grid-cols-12 grid-rows-1 col-start-1 col-end-2 row-start-1 divide-x divide-gray-100;

                    .avo-week-calendar-day-content {
                        @apply flex flex-col gap-2 p-2;

                        &.start-1 {
                            @apply col-start-1 sm:col-start-1;
                        }

                        &.start-3 {
                            @apply col-start-1 sm:col-start-3;
                        }

                        &.start-5 {
                            @apply col-start-1 sm:col-start-5;
                        }

                        &.start-7 {
                            @apply col-start-1 sm:col-start-7;
                        }

                        &.start-9 {
                            @apply col-start-1 sm:col-start-9;
                        }

                        &.start-11 {
                            @apply col-start-1 sm:col-start-11;
                        }

                        &.start-12 {
                            @apply col-start-1 sm:col-start-12;
                        }

                        &.weekday {
                            @apply sm:col-span-2;
                        }
                    }
                }
            }

            .avo-week-calendar-mobile-body {
                @apply h-full w-full grid sm:hidden;
            }
        }
    }
}
