.similar-company-modal-container {
    .similar-company-modal-content-container {
        max-height: 600px;

        @apply space-y-2 px-4 overflow-y-auto;

        .similar-company-info-card {
            @apply shadow-sm rounded border border-gray-100 hover:bg-gray-100 hover:cursor-pointer;

            .similar-company-info-card-header {
                @apply px-5 py-2 border-b border-gray-100;

                .company-name {
                    @apply text-lg font-semibold;
                }
            }

            .similar-company-info-card-content {
                @apply text-sm px-5 py-2 space-y-1;

                .company-info-row {
                    @apply flex items-center gap-x-2 p-1;

                    .company-info-key {
                        @apply text-xs;
                    }

                    .company-info-value {
                        @apply font-medium;
                    }
                }

                > :nth-child(even) {
                    @apply bg-gray-100;
                }

                > :nth-child(odd) {
                    @apply bg-white;
                }
            }

            &.selected {
                @apply border border-primary;

                .similar-company-info-card-header {
                    .company-name {
                        @apply text-primary font-bold;
                    }
                }
            }
        }
    }

    .similar-company-modal-buttons-container {
        @apply flex gap-5 px-4 mt-4;
    }
}
