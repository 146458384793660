.dashboard-container {
    .dashboard-card-container {
        &.task-card {
            @apply col-span-2 lg:col-span-4;

            .avo-datatable-container {
                .avo-datatable-container-inner {
                    .avo-datatable-container-shadow {
                        @apply shadow-none;

                        .avo-datatable {
                            .avo-datatable-head {
                                .avo-datatable-head-td {
                                    .avo-datatable-header-container {
                                        .loader-container {
                                            .table-title {
                                                @apply text-xl font-medium leading-8 text-gray-900;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
