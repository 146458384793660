.task-status-badge {
    @apply py-1 px-2 rounded-md;

    .task-status-badge-text {
        @apply text-xs font-bold text-white;
    }

    &.active {
        @apply bg-avo-green;
    }

    &.finished {
        @apply bg-gray-500;
    }
}
