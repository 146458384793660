.avo-tabs-container {
    @apply w-full border-b border-gray-200;

    .avo-tabs-nav {
        @apply w-full -mb-px flex;

        .avo-tab {
            @apply w-full whitespace-nowrap border-b-2 py-4 px-1 text-sm text-center font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 hover:cursor-pointer;

            &.selected {
                @apply border-primary text-primary;
            }
        }
    }
}
