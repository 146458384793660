.steps-container {
    @apply h-18 mb-4 bg-white lg:border-t lg:border-b lg:border-gray-200;

    .steps-nav {
        @apply mx-auto max-w-7xl;

        .steps-list {
            @apply rounded-md overflow-hidden flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none;
        }
    }
}
