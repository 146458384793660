.sidebar-desktop {
    @apply hidden bg-primary md:flex md:flex-shrink-0;

    a {
        @apply no-underline;

        &:first-child {
            & > .link {
                @apply mt-0;
            }
        }

        .link {
            @apply h-10 flex flex-col justify-center mt-1 py-0 px-5 border border-gray-500;

            &:hover {
                @apply bg-gray-500;
            }

            .link-text {
                @apply text-black;
            }
        }
    }
}

.profile-section {
    @apply flex-shrink-0 flex border-t border-primary-dark p-4;

    .user-name {
        @apply text-base font-medium text-white;
    }

    .selected-entity {
        @apply text-xs font-medium text-white hover:text-white;
    }

    a.change-entity-link {
        @apply text-xs text-white hover:scale-150 bg-avo-green rounded-sm p-1;
    }

    .logout-button-container {
        @apply flex items-center justify-end hover:cursor-pointer;

        .logout-icon {
            @apply h-6 w-6 text-white transform hover:scale-110 hover:text-gray-100;
        }
    }
}
