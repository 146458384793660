.stack-datatable {
  @apply flex gap-2 h-content;
}

.stack-section-container {
    @apply w-full flex flex-col gap-6  bg-gray-50 shadow rounded-lg p-4;

    .section-title-container {
        @apply flex justify-between items-center;

        .section-title {
            @apply text-xl font-bold mr-2;
        }
    }

    .stack-items-list-container {
        @apply overflow-auto pr-4;

        .stack-section-item {
            @apply flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 bg-white;
        }
    }

    .center-loader {
        @apply flex justify-center items-center pb-4;
    }
}
