.pagination-container {
    @apply border-t border-gray-200 px-4 flex items-center;

    .pagination-button-container {
        @apply -mt-px w-0 flex-1 flex pb-4;

        .pagination-button {
            @apply pt-4 pr-1 flex justify-center items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:cursor-pointer;

            .pagination-button-icon {
                @apply h-5 w-5 text-gray-400;
            }

            .pagination-button-text {
                @apply select-none;
            }
        }

        &.previous {
            .pagination-button {
                .pagination-button-icon {
                    @apply mr-3;
                }
            }
        }

        &.next {
            @apply justify-end;

            .pagination-button {
                .pagination-button-icon {
                    @apply ml-3;
                }
            }
        }
    }

    .pagination-pages-container {
        @apply hidden md:-mt-px md:flex pb-4;

        .pagination-page-button {
            @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 hover:cursor-pointer border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium;

            &.current {
                @apply border-primary text-primary;
            }
        }

        .pagination-page-separator {
            @apply border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium select-none;
        }
    }

    .pagination-page-size-container {
        @apply ml-3 flex items-center gap-2;

        .avo-select-container {
            @apply ml-3;
        }

        .vl {
            @apply border rounded border-gray-200 h-7;
        }
    }
}
