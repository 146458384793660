.addendum-add-form-container {
    .offset-split-row {
        @apply grid grid-cols-3 gap-6;

        > :nth-child(1) {
            @apply col-span-3  sm:col-span-2;
        }

        > :nth-child(2) {
            @apply col-span-3  sm:col-span-1;
        }

        &.flipped {
            > :nth-child(1) {
                @apply col-span-3  sm:col-span-1;
            }

            > :nth-child(2) {
                @apply col-span-3  sm:col-span-2;
            }
        }
    }

    .split-row {
        @apply grid grid-cols-2 gap-6;

        & > * {
            @apply col-span-2 lg:col-span-1 md:col-span-2 sm:col-span-1;
        }
    }

    .split-row-3 {
        @apply grid grid-cols-3 gap-3 items-center;
    }

    .split-row-4 {
        @apply grid grid-cols-4 gap-3 items-center;
    }

    .form-section-title {
        @apply text-base font-medium text-gray-900 mt-0;
    }

    .recurring-months-input-container {
        @apply flex items-center gap-3;
    }
}
