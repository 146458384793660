.avo-color-picker-modal {
    .avo-color-picker-modal-content-container {
        @apply flex justify-center gap-12;

        .avo-color-picker-modal-left {
            @apply w-1/2 flex flex-col gap-4 items-center;

            .react-colorful {
                @apply w-full;
            }
        }

        .avo-color-picker-modal-right {
            @apply w-1/2;

            .default-colors-title {
                @apply text-xs font-semibold text-gray-500 mb-2;
            }

            .default-colors-container {
                @apply grid grid-cols-5 gap-4;

                .select-color-container {
                    @apply col-span-1 hover:cursor-pointer;

                    .avo-color-preview.selected {
                        @apply border-2 border-black;
                    }
                }
            }
        }
    }

    .avo-color-picker-modal-buttons-container {
        @apply w-full mt-5 flex justify-end gap-2;
    }
}
