.infobox-container {
    @apply flex flex-col items-center p-4 h-full rounded bg-white shadow;

    .infobox-title {
        @apply text-lg font-bold leading-6 text-gray-900 mb-1;
    }

    .infobox-description {
        @apply max-w-prose text-sm text-center text-gray-500;
    }

    .infobox-content {
        @apply w-full flex justify-center gap-2 mt-4 divide-x divide-gray-100 border-t border-gray-100;

        .infobox-info-container {
            @apply w-1/2 p-4;

            .infobox-description {
                @apply text-left mt-2;
            }

            .infobox-title-container {
                @apply flex gap-4 items-baseline mb-2;

                .infobox-title {
                    @apply text-base font-semibold leading-6 text-gray-900;
                }
            }

            .infobox-text {
                @apply text-sm leading-5 text-gray-500;

                .infobox-value {
                    @apply text-sm font-semibold leading-6 text-gray-900;
                }
            }

            .infobox-user-header-container {
                .infobox-user-text {
                    @apply text-sm font-bold text-gray-900;
                }
            }

            .infobox-user-content-container {
                @apply border-t border-gray-100 pt-1 pb-1 mb-1;
            }

            .infobox-user-footer-container {
                @apply mt-2;
            }

            .infobox-user-row {
                @apply grid grid-cols-7 mb-0.5;

                > *:first-child {
                    @apply col-span-3;
                }

                > *:not(:first-child) {
                    @apply col-span-2;
                }

                .infobox-user-bold-text {
                    @apply text-sm font-semibold text-gray-900;
                }
            }

            .infobox-loader-contaier {
                @apply w-full flex justify-center items-center;
            }
        }
    }
}
