.avo-select-container {
    @apply flex flex-col;

    &.label-inline {
        @apply flex-row items-center gap-2;
    }

    .avo-select-label {
        @apply block mb-1 text-sm font-medium text-gray-700 whitespace-nowrap;
    }

    .avo-select {
        .avo-select__control {
            @apply text-sm rounded-md shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary focus-within:border-gray-300;

            .avo-select__value-container {
                .avo-select__multi-value {
                    @apply bg-gray-300;

                    .avo-select__multi-value__remove {
                        @apply hover:bg-avo-red hover:text-white;
                    }
                }
            }
        }

        &.avo-select--is-disabled {
            .avo-select__control--is-disabled {
                @apply bg-gray-100;

                .avo-select__value-container {
                    .avo-select__single-value,
                    .avo-select__multi-value {
                        @apply text-gray-400;
                    }
                }

                .avo-select__indicators {
                    .avo-select__indicator {
                        @apply text-gray-400;
                    }
                }
            }
        }

        &.small {
            .avo-select__control {
                .avo-select__value-container {
                    @apply py-0 pl-1 pr-0.5 text-sm sm:text-xs;
                }
            }
        }

        &.medium {
            .avo-select__control {
                .avo-select__value-container {
                    @apply pl-3 pr-0.5 py-1 text-base sm:text-sm;
                }
            }
        }

        &.large {
            .avo-select__control {
                .avo-select__value-container {
                    @apply pl-5 pr-0.5 py-3 text-lg sm:text-base;
                }
            }
        }

        &.full-width {
            @apply w-full;
        }
    }
}

.avo-select__menu {
    .avo-select__menu-list {
        .avoapp-select-no-options-custom-message {
            @apply text-left hover:cursor-pointer;

            span {
                @apply font-bold;
            }
        }
    }
}
